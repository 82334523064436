//____ COOKIE WINDOW POP-UP ____ //
//____ AUTHOR: LUBOMÍR BLAŽEK & MICHAL SOBČÁK ____ //
(function($) {
    var $fn = $(".part_ui_cookies");
    if($fn.length && document.cookie.indexOf('cookies-confirmation=') === -1) {
        setTimeout(function(){
            $fn.addClass("mod--active mod--animate");
        },1000);
        $fn.find("[data-cookies-confirm]").on('click', function() {
            var date = new Date();
            date.setFullYear(date.getFullYear() + 10);
            document.cookie = "cookies-confirmation=1; path=/; expires=" + date.toGMTString();
            $fn.removeClass("mod--animate");
            setTimeout(function(){
                $fn.removeClass("mod--active").remove();
            },500);
        });
    }
})(jQuery);