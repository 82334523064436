(function($) {
    $doc.on("click", ".part_ui_select select", function(){
        $(this).parent().toggleClass("mod--focus");
        $(this).one("blur",function() {
            $(this).parent().removeClass("mod--focus");
        });
    });
    $doc.on("change", ".part_ui_select select", function(){
        if ($(this)[0].selectedIndex === 0) {
            $(this).parent().addClass("mod--placeholder");
        } else {
            $(this).parent().removeClass("mod--placeholder");
        }
    });
})(jQuery);