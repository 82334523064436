function cssLoaded(callback) {
    if ($("[data-loadcss]").length) {
        let css_wait = setInterval(function(){
            if ($body.css("margin-left") === "0px") {
                clearInterval(css_wait);
                callback();
            }
        },0);
    } else {
        callback();
    }
}