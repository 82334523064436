(function ($) {
    let $fn = $(".comp_slider_rooms");

    if ($fn.length) {
        let $slider = $fn.find("[data-slider]");

        if($slider.children().length > 3) {
            $slider.slick({
                slidesToShow: 3,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: "0px",
                arrows: true,
                dots: false,
                focusOnSelect: false,
                prevArrow: "<i class=\"icon icon--arrow-left slick-prev\"></i>",
                nextArrow: "<i class=\"icon icon--arrow-right slick-next\"></i>",
                responsive: [
                    {
                        breakpoint: 960,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 640,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
            })
        }
    }
})(jQuery);